import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { H1 } from '../styled/Heading'
import Contain from '../styled/Contain'

const Copy = props => {
  const { title, html } = props

  return (
    <CopyWrapper>
      <Contain size="medium">
        <H1 marginTop>{title}</H1>
      </Contain>
      <CopyContent size="small" dangerouslySetInnerHTML={{ __html: html }} />
    </CopyWrapper>
  )
}

Copy.propTypes = {
  title: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
}

export default Copy

const CopyWrapper = styled.section`
  margin-top: 40px;

  @media (min-width: ${props => props.theme.layout.breakpoints.large}) {
    margin-top: 60px;
  }
`

const CopyContent = styled(Contain)`
  > * + * {
    margin-top: 32px;
  }

  > p > .gatsby-resp-image-wrapper {
    max-width: 100% !important;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  > * + h2 {
    margin-top: 64px;
  }

  > *:first-child {
    margin-top: 64px;
  }

  ul {
    li {
      display: list-item;
      position: relative;
      padding-left: 40px;

      &::before {
        content: '—';
        position: absolute;
        left: 0;
        color: ${props => props.theme.colors.secondary};
        font-weight: ${props => props.theme.fontWeights.heading};
      }
    }
  }

  ol {
    counter-reset: list;
    > li {
      &::before {
        content: counter(list) '.';
        margin-right: 26px;
        color: ${props => props.theme.colors.secondary};
        font-weight: ${props => props.theme.fontWeights.heading};
        font-variant-numeric: tabular-nums;
        counter-increment: list;
      }
    }
  }

  h2 {
    color: ${props => props.background && props.theme.colors.background};
    font-size: ${props => props.theme.fontSizes.h2};
    letter-spacing: ${props => props.theme.letterSpacings.h2};
  }

  h3 {
    color: ${props => props.background && props.theme.colors.background};
    font-size: ${props => props.theme.fontSizes.h3};
    letter-spacing: ${props => props.theme.letterSpacings.h3};
  }

  h4 {
    color: ${props => props.background && props.theme.colors.background};
    font-size: ${props => props.theme.fontSizes.h4};
    letter-spacing: ${props => props.theme.letterSpacings.h4};
  }
`
