import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Contain from '../styled/Contain'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Copy from '../components/Copy'

export default function Privacy({ data }) {
  return (
    <Layout>
      <Contain>
        <Copy
          title={data.markdownRemark.frontmatter.title}
          html={data.markdownRemark.html}
        />
      </Contain>
    </Layout>
  )
}

export const Head = () => <SEO title="Privacy verklaring" />

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "privacy" } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`

Privacy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}
